@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px !important;
}

:root {
  --plyr-color-main: #FFFA60;
;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.header-bg {
  background-size: cover !important;
  /*background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.0) ), url("assets/images/header-bg-no-kaissa.png") bottom left;*/
  background: url("assets/images/header-bg-no-kaissa.png") bottom left;
}

@media (max-width: 640px) {
  .header-bg {
    background-size: cover !important;
    background: url("assets/images/header-bg-mobile.png");
  }
}
.header-logo{
  width: auto;
  height: 100%;
}
.countdown-pill {
  background: #FFF066;
  box-shadow: 0 0 18px rgba(255, 230, 0, 0.8);
  backdrop-filter: blur(4px);
  color: #10193E;
}
.app-tabs {
  background-image: url("assets/images/bg.png") !important;
  background-size: cover;
  color: #DCE3FF;
}

@media (max-width: 700px) {
  .app-tabs {
    background: rgb(18, 27, 60) !important;
  }
}

.advantage-icon {
  background: #8E96BD;
  box-shadow: 0 10px 19px #070E29;
  border-radius: 16px;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.95);
}

.h1-title {
  color: white;
  font-size: 36px;
  font-weight: 700;
}

.team-member-img {
  background-blend-mode: color, normal;
  background-size: cover !important;
  background-position: center !important;
}

.chess-menu .ant-drawer-content{
  background: transparent;
}

.shadow-blue {
  box-shadow: 0 0 18px rgba(144, 255, 255, 0.8) !important;
}

.shadow-yellow {
  box-shadow: 0 0 18px rgba(255, 225, 0, 0.58) !important;
}

.shadow-green{
  box-shadow: 0 0 18px rgba(82, 196, 26, 0.58) !important;
}
